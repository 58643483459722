import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import AllInvoices from "../../components/AllInvoices/all-invoices";
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PendingInvoiceSummary = (props) => {
    const [invoiceData, setInvoiceData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [timer, setTimer] = useState(null);

    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getInvoiceData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getInvoiceData = (e) => {
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/sent-invoice-summary?search=${e !== undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setInvoiceData(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getInvoiceData();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Invoice-summary | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">
                                        {props.t("Pending Invoice Summary")}
                                    </h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>{props.t("Reference")}</th>
                                                <th>{props.t("Client")}</th>
                                                <th>{props.t("E-billing status")}</th>
                                                <th>{props.t("Created by")}</th>
                                                <th>{props.t("Status")}</th>
                                                <th>{props.t("Date")}</th>
                                                <th>{props.t("Amount")}</th>
                                                <th>{props.t("Activity")}</th>
                                                <th>{props.t("Margin")}</th>
                                                <th>{props.t("Unpaid")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceData &&
                                                invoiceData.map((item, i) => (
                                                    <AllInvoices data={item} key={i} />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(PendingInvoiceSummary);
PendingInvoiceSummary.propTypes = {
    t: PropTypes.any,
};
