import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CreateTrialModal from "../plans/trialModal";
import Paginations from "../../components/pagination";

const Clients = (props) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [ref_filter, setRef_filter] = useState(false);
    const [name_filter, setName_filter] = useState(false);
    const [legalName_filter, setLegalName_filter] = useState(false);
    const [phone1_filter, setPhone1_filter] = useState(false);
    const [email_filter, setEmail_filter] = useState(false);
    const [tin_filter, setTin_filter] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [paginationData, setPaginationData] = useState("");

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/client/new");
        }
    };
    const getAllAssests = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getTemplate(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getTemplate = (e) => {
        setLoader(<Loader />)
        setData([]);
        setPaginationData("");
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-all-clients?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.clients.data);
                        setPaginationData(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-all-clients`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setData(res.data.clients.data);
                        setPaginationData(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const paginateData = (link) => {
        setPaginationData("");
        setData([]);
        setLoader(<Loader />);
        let url = `${link}`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getSearchedList = (e, type) => {
        setLoader(<Loader />)
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "name") {
            localStorage.setItem("name", e);
        }
        if (type == "legal_name") {
            localStorage.setItem("legalName", e);
        }
        if (type == "tin") {
            localStorage.setItem("tinNumber", e);
        }
        if (type == "phone_1") {
            localStorage.setItem("phone1", e);
        }
        if (type == "email") {
            localStorage.setItem("email", e);
        }
        setData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients?&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&name=${localStorage.getItem("name") !== null
                    ? localStorage.getItem("name")
                    : ""
                }&legal_name=${localStorage.getItem("legalName") !== null
                    ? localStorage.getItem("legalName")
                    : ""
                }&tin=${localStorage.getItem("tinNumber") !== null
                    ? localStorage.getItem("tinNumber")
                    : ""
                }&phone_1=${localStorage.getItem("phone1") !== null
                    ? localStorage.getItem("phone1")
                    : ""
                }&email=${localStorage.getItem("email") !== null
                    ? localStorage.getItem("email")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                    setRef_filter(false);
                    setName_filter(false);
                    setLegalName_filter(false);
                    setPhone1_filter(false);
                    setEmail_filter(false);
                    setTin_filter(false);
                } else {
                    setError(res.data.message);
                }
                setLoader("")
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    getTemplate();
                    setdynamic_description(res.data.message);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/client-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    const mergeDuplicates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/merge-duplicate-clients`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setSuccess(res.data.message);
                    getTemplate()
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 8000);
            });
    };

    const getDuplicates = (e) => {
        setData([]);
        setPaginationData("");
        setLoader(<Loader />)
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients?getDuplicate=${e}`,

                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getTemplate();
    }, []);
    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        deleteMultiple();
                        setdynamic_title("Deleted");
                        setdynamic_description("Your file has been deleted.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    <span className="text-danger">
                        You won&apos;t be able to revert this!
                    </span>
                </SweetAlert>
            ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>Clients | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <h6 className="page-title">{props.t("Clients")}</h6>
                                </Col>
                                <Col md={8}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getAllAssests(e.target.value)}
                                            />
                                        </div>
                                        {getPermission().clients.clients.create.is_checked === 1 ? (
                                            <>
                                                <div className="btn-group me-2">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            className="btn btn-primary"
                                                        // id="dropdown-basic"
                                                        >
                                                            {props.t("More")}
                                                            {/* <i className="fa fa-caret-down ms-2"></i> */}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {/* <Dropdown.Item>
                            <li>
                              <span
                                //   className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setconfirm_alert(true);
                                }}
                              >
                                Batch delete
                              </span>
                            </li>
                            {/* </ul> */}
                                                            {/* </Dropdown.Item> */}
                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getExport()}
                                                                    >
                                                                        {props.t("Export")}
                                                                    </span>
                                                                </li>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => mergeDuplicates()}
                                                                    >
                                                                        {props.t("Merge Duplicate Clients")}
                                                                    </span>
                                                                </li>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicates("legal_name")}
                                                                    >
                                                                        {props.t("Legal Name Duplicates")}
                                                                    </span>
                                                                </li>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item>
                                                                <li className="nav-item mb-2">
                                                                    <span
                                                                        // className="dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicates("tin")}
                                                                    >
                                                                        {props.t("Ced/Ruc Duplicates")}
                                                                    </span>
                                                                </li>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => createNew()}
                                                >
                                                    {props.t("Create New")}
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {success ? <Alert color="success">{props.t(success)}</Alert> : ""}
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        <Card>
                            <CardBody>
                                <div className="overflow-auto w-100">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Legal Name")}
                                                        {legalName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setLegalName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("legalName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setLegalName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setLegalName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            legalName_filter={legalName_filter}
                                                            setLegalName_filter={setLegalName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                {/* <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Name")}
                                                        {name_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("name") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            name_filter={name_filter}
                                                            setName_filter={setName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th> */}
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Ced/Ruc")}
                                                        {tin_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setTin_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("tinNumber") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setTin_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setTin_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            tin_filter={tin_filter}
                                                            setTin_filter={setTin_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Phone")}
                                                        {phone1_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setPhone1_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("phone1") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setPhone1_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setPhone1_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            phone1_filter={phone1_filter}
                                                            setPhone1_filter={setPhone1_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Email")}
                                                        {email_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setEmail_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("email") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setEmail_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setEmail_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            email_filter={email_filter}
                                                            setEmail_filter={setEmail_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                {/* <th>consumption-tax</th> */}
                                                <th>{props.t("Agent")}</th>
                                                <th>{props.t("Activity")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((item, i) => (
                                                    <SingleList
                                                        data={item}
                                                        key={i}
                                                        i={i}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                        {paginationData ? (
                            <Paginations data={paginationData} paginate={paginateData} />
                        ) : (
                            ""
                        )}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Clients);
Clients.propTypes = {
    t: PropTypes.any,
};
