/* eslint-disable use-isnan */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Table,
    Card,
    Spinner,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Input,
} from "reactstrap";
// import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import Select from "react-select";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../../assets/images/def-img.svg";
import Loader from "../../../components/Loader";
import TechnicalTablesAttachment from "../../../components/Attachments/technical-tables";
import ClientAddress from "../../../components/ClientAddresses";
import ProductTechEstimates from "../../../components/Product/estimates";
import NewPayment from "../../../components/PaymentOption/payment_option";
import DeliveryOptionModal from "../../../components/DeliveryOption/modal";
import ClientList from "../../../components/ClientList";
import AssetsNew from "../../../components/Assests/assests-new";
import { Link } from "react-router-dom";
import AssignUserList from "../../../components/AssignUserList";
import ClientCustomDropdown from "../../../components/ClientDropdown";
import ClientWorkAddress from "../../../components/ClientAddresses/workAddress";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import DownloadOption from "../../Invoicing/download";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import SendEmail from "../../../pages/sendEmail";
import fileSaver from "file-saver";
import SendAttachment from "../../SendAttachments/index";
import PrintOption from "../../MorePrintOption";
import moment from "moment";
let totalAmount;
let subTotal;
let subTotalShow;
let dValue;
let assetValue = [];
let totalBasePrice;
let totalDisc;
let totalTax;
let otherTax;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import CreateTrialModal from "../../plans/trialModal";
import DefaultPrintButtons from "../../../components/DefaultPrintButtons";

let searchedClient;

const EditTechnicalTable = (props) => {
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [clients, setClients] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [clientNameData, setClientNameData] = useState("");
    const [workData, setWorkData] = useState("");
    const [allRates, setAllRates] = useState([]);
    const [selectedCates, setSelectedCates] = useState("");
    // const [userdel, setUserDel] = useState(props.Attached)
    const [clientAddress, setClientAddress] = useState("");
    const [saleData, setSaleData] = useState("");
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [data, setData] = useState([]);
    const [attachDocument, setAttachDocument] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [selectedCate, setSelectedCate] = useState("");
    const [clientFull, setClientFull] = useState("");
    const [startDate, setStartDate] = useState("");
    const [emailDate, setEmailDate] = useState("");
    const [sentDate, setSentDate] = useState("");
    const [scheduleSentDate, setScheduleSentDate] = useState("");
    const [validDate, setValidDate] = useState("");
    const [client2, setClient2] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("pro");
    const [assetId, setAssetId] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [assetIdString, setAssetIdString] = useState('');
    const [assetSelected, setAssetSelected] = useState({ label: "", value: "" });
    const [mod, setMod] = useState(false);
    const [mods, setMods] = useState(false);
    const [startDate2, setStartDate2] = useState("");
    const [endDate, setEndDate] = useState("");
    const [assignedDate, setAssignedDate] = useState("");
    const [deliveryOptionModal, setDeliveryOptionModal] = useState(false);
    const [isOpenModalNew, setIsOpenModalNew] = useState(false);
    const [assetModal, setassetModal] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [assetsLoader, setAssetsLoader] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [deliveryOptionLoader, setDeliveryOptionLoader] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState([]);
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [discountValue, setDiscountValue] = useState(0);
    const [selectClientModal2, setSelectClientModal2] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [clientData, setClientData] = useState("");
    const [selectClientId2, setSelectClientId2] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState("");
    const [assignedUserName, setAssignedUserName] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [clientSelect, setClientSelect] = useState("");
    const [clientLoader, setClientLoader] = useState(false);
    const [mainFormDisabled, setMainFormDisabled] = useState(false);
    const [isWorkAddress, setIsWorkAddress] = useState(false);
    const [timer, setTimer] = useState(null);
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [refType, setRefType] = useState("");
    const [fields, setFields] = useState({
        formValues: [],
    });
    const [isDownloadModal, setIsDownloadModal] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [bank, setBank] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [template1, setTemplate1] = useState("");
    const [priceTax, setPriceTax] = useState("");
    const [mailingFormat, setMailingFormat] = useState("");
    const [productService, setProductService] = useState("");
    const [defaultConfig, setDefaultConfig] = useState("");
    const [email, setEmail] = useState(getCurrentUser().email);
    const [email2, setEmail2] = useState("");
    const [emailMessage, setEmailMessage] = useState(
        "Esteemed @CLIENTNAME@,<br><br>You will find your @DOCUMENTTYPE@ attached to this email.<br><br>Best regards and thank you for placing your trust in @MYCOMPANY@.<br>@USERNAME@"
    );
    const [isSendModal, setIsSendModal] = useState(false);
    const [link, setLink] = useState("");
    const [format, setFormat] = useState("");
    const [templateData, setTemplateData] = useState([]);
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [assignedModal1, setAssignedModal1] = useState(false);
    const [assignedModal2, setAssignedModal2] = useState(false);
    const [assignedUserName1, setAssignedUserName1] = useState("");
    const [assignedUserId1, setAssignedUserId1] = useState("");
    const [agentUserName, setAgentUserName] = useState("");
    const [agentUserId, setAgentUserId] = useState("");
    const [error4, setError4] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [allBanks, setAllBanks] = useState([]);
    const [isPrintModal, setIsPrintModal] = useState(false);
    const [link1, setLink1] = useState("");
    const [messageData, setMessageData] = useState("");
    const [signedBox, setSignedBox] = useState("");
    const [defaultDeliveyOption, setDefaultDeliveryOption] = useState({})
    const [paginationData, setPaginationData] = useState("");
    let params = new URLSearchParams(window.location.search);
    const [generate] = useState(params.get("generate"));
    const [rateValue, setRateValue] = useState("");
    const [mailPDFFormat, setMailPDFFormat] = useState("");
    const history = useHistory();
    const [nonActive, setNonActive] = useState(
        (JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            JSON.parse(localStorage.getItem("plan_data"))
                ?.stripe_subscription_idon_id) ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };

    const backPage = () => {
        if (saleData.reference_type === "Work Order") {
            history.push(`/work-order/`);
        } else if (saleData.reference_type === "Work Delivery Note") {
            history.push(`/work-delivery/`);
        } else {
            history.push(`/estimates/`);
        }
    };

    const getClients = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const createAttachment = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("technical_id", userId);
        formData.append("document", document);
        formData.append("description", e.target.description.value);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-table-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    AllAttach();
                    setSuccess(res.data.message);
                    setMods(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const AllAttach = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-table-attachments`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setAttachDocument(res.data.technical_attachments);
            });
    };

    const getAllRates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllRates(res.data.rates);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    const getDeliveryOption = () => {
        setDeliveryOptionLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/delivery-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDeliveryOption(res.data.delivery_options);
                res.data.delivery_options?.map((item) => {
                    if (item.by_default === "1") {
                        setDefaultDeliveryOption({ value: item.id, label: item.name });
                    }
                });
                setDeliveryOptionLoader(false);
            });
    };

    const getClientData = (e) => {
        setClientLoader(true);
        setAllClients([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/get-all-clients`;
        if (e !== undefined) {
            searchedClient = e;
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/get-all-clients?search=${searchedClient}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setAllClients(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setAllClients([]);
                    setError(res.data.message);
                }
                setClientLoader(false);
                setTimeout(() => {
                    setError("");
                    //   setSuccess("");
                }, 2000);
            });
    };

    const paginateData = (link) => {
        setPaginationData("");
        setAllClients([]);
        setClientLoader(true);
        let url = `${link}&search=${searchedClient ? searchedClient : ""}`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setClientLoader(false);
                if (res.data.status) {
                    setError("");
                    setAllClients(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        AllAttach();
        getClientData();
        NewpaymentShow();
        getDeliveryOption();
        getAllRates();
    }, []);

    const getSaleData = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-tables/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSaleData(res.data.data);
                    setPaymentOption(res.data.data.payment_option);
                    setSelectedCates(res.data.data.payment_option);
                    getTemplate(res.data.data.reference_type);
                    setRefNum(res.data.data.reference_number);
                    setRefType(res.data.data.reference_type);
                    setImgPreview(res.data.data.signature);
                    setSentDate(res.data.data.sent_date);
                    setSelectClientId(res.data.data.client_id);
                    setClientNameData(res.data.data.client_name);
                    setClientData(res.data.data.inv_address);
                    setWorkData(res.data.data.work_address);
                    setAssetIdString(res.data.data.asset_id)
                    setAssignedUserId1(res.data.data.created_by);
                    setAssignedUserName1(res.data.data.created_by_name);
                    if (res.data.data.payment_option === "1") {
                        // setBank(true);
                    }
                    if (res.data.data.email_sent_date !== null) {
                        const momentDate1 = moment(
                            res.data.data.email_sent_date,
                            "DD-MM-YYYY"
                        );
                        setEmailDate(momentDate1.toDate());
                    }
                    setAssignedUserName(res.data.data.assigned_to);
                    setAgentUserId(res.data.data.agent_id);
                    setAgentUserName(res.data.data.agent_name);
                    const momentDate = moment(res.data.data.date, "DD-MM-YYYY");
                    setStartDate(momentDate.toDate());
                    setFields({
                        formValues: res.data.data.items?.map((option) => ({
                            reference: option.reference,
                            reference_id: option.reference_id,
                            reference_number: option.product_reference_number,
                            name: option.name,
                            description: option.description,
                            base_price: option.base_price,
                            quantity: option.quantity,
                            discount: option.discount,
                            amount: option.amount_with_out_vat,
                            vat: option.vat,
                            tax: option.tax,
                            subtotal: option.subtotal,
                            discAmount: option.discAmount !== null ? option.discAmount : 0,
                            taxAmount: option.taxAmount,
                            otherTaxAmount: option.otherTaxAmount,
                            showPopup: option?.showPopup,
                            isUpdate: option?.isUpdate,
                        })),
                    });

                    setScheduleSentDate(res.data.data.schedule_delivery_date);
                    setDiscountValue(res.data.data.meta_discount);

                    AssetsData(res.data.data.client_id, res.data.data.asset_id);
                    singleClientdata(res.data.data.client_id);
                    getReferences(res.data.data.reference_type);
                    getDefaultPdf(res.data.data.reference_type);
                }
            });
    };
    useEffect(() => {
        getSaleData();
    }, []);

    const CreateSale = (e, value) => {

        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        // formData.append("reference_number", value.reference_number);
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("date", e.target.date.value);
        // formData.append("date", value.date !== null ? value.date : "");
        formData.append("client_id", selectClientId);
        formData.append("status", value.status !== null ? value.status : "");
        formData.append(
            "payment_option",
            e.target.payment_option.value !== null
                ? e.target.payment_option.value
                : ""
        );
        formData.append("title", value.title);
        formData.append("rate", value.rate !== null ? value.rate : "");
        formData.append(
            "inv_address",
            value.inv_address !== null ? value.inv_address : ""
        );
        formData.append(
            "work_address",
            value.work_address !== null ? value.work_address : ""
        );
        formData.append("currency", value.currency !== null ? value.currency : "");
        formData.append("email_sent_date", e.target.email_sent_date.value);
        if (saleData.reference == "WE") {
            formData.append("valid_until", e.target.valid_until.value);
        } else {
            if (saleData.reference !== "WO") {
                formData.append("assigned_to", assignedUserId);
                formData.append(
                    "assigned_date",
                    value.assigned_date !== null ? value.assigned_date : ""
                );
            }
            formData.append("end_date", endDate);
            formData.append("due_date", scheduleSentDate);
            formData.append("start_date", startDate2);
            formData.append(
                "delivery_option",
                value.delivery_option !== null ? value.delivery_option : ""
            );
        }
        formData.append("invoice_to", selectClientId2);
        formData.append(
            "currency_rate",
            value.currency_rate !== null ? value.currency_rate : ""
        );
        formData.append("comments", value.comments !== null ? value.comments : "");
        formData.append(
            "private_comments",
            e.target.private_comments.value !== null ? e.target.private_comments.value : ""
        );
        formData.append("addendum", value.addendum !== null ? value.addendum : "");
        formData.append("name", value.name !== null ? value.name : "");
        formData.append("tin", value.tin);
        formData.append("signature", image);
        // formData.append("asset_id", assetId !== null ? assetId : "");
        const arrValues = assetId.map(item => item.value);
        const valuesString = arrValues.join(',');
        formData.append("asset_id", valuesString);

        // formData.append("asset_id", value.assets !== null ? value.assets : "");
        formData.append("meta_discount", e.target.meta_discount.value);
        formData.append("created_by", assignedUserId1);
        formData.append("agent_id", agentUserId);
        formData.append("item", JSON.stringify(fields.formValues));
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-tables/${saleData.id}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    // setAssetID(res.data.client.id)
                    setIsEditable(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                    if (res.data.message === "Ced/Ruc number is required") {
                        setCustomActiveTab("5");
                    }
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };

    const [fieldSection, setFieldSection] = useState({
        formValues: [{ message: "", cast: "" }],
    });
    const [sec, setSec] = useState(false);

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const handleChange = (i, e) => {
        let formValues = fields.formValues;
        formValues[i][e.target.name] = e.target.value;
        setFields({ formValues });
    };

    const addFormFields = () => {
        setFields({
            formValues: [
                ...fields.formValues,
                {
                    reference: "",
                    reference_id: "",
                    reference_number: "",
                    name: "",
                    description: "",
                    base_price: "",
                    quantity: "1",
                    discount: "",
                    amount: "0",
                    vat: "0",
                    tax: "0",
                    subtotal: "0",
                    discAmount: "0",
                    taxAmount: "0",
                    otherTaxAmount: "0",
                    showPopup: "0",
                    isUpdate: "0",
                },
            ],
        });
    };

    const removeFormFields = (i) => {
        let formValues = fields.formValues;
        formValues.splice(i, 1);
        setFields({ formValues });
    };

    const addFormFieldSection = () => {
        setSec(true);
        setFieldSection({
            formValues: [...fieldSection.formValues, { message: "", cast: "" }],
        });
    };

    totalAmount = fields.formValues.reduce(
        (totalAmount, currentValue) =>
            (totalAmount = totalAmount + parseFloat(currentValue.amount)),
        0
    );

    subTotal = fields.formValues.reduce(
        (subTotal, currentValue) =>
            (subTotal = subTotal + parseFloat(currentValue.subtotal)),
        0
    );
    subTotalShow = subTotal - (subTotal * discountValue) / 100;
    dValue = (subTotal - subTotalShow).toFixed(2);

    totalBasePrice = fields.formValues.reduce(
        (totalBasePrice, currentValue) =>
            (totalBasePrice = totalBasePrice + parseFloat(currentValue.amount)),
        0
    );
    totalDisc = fields.formValues.reduce(
        (totalDisc, currentValue) =>
            (totalDisc = totalDisc + parseFloat(currentValue.discAmount)),
        0
    );
    totalTax = fields.formValues.reduce(
        (totalTax, currentValue) =>
            (totalTax = totalTax + parseFloat(currentValue.taxAmount)),
        0
    );

    otherTax = fields.formValues.reduce(
        (otherTax, currentValue) =>
            (otherTax = otherTax + parseFloat(currentValue.otherTaxAmount)),
        0
    );

    const metaDiscount = (e) => {
        if (e == undefined) {
            subTotal = fields.formValues.reduce(
                (subTotal, currentValue) =>
                    (subTotal = subTotal + parseFloat(currentValue.subtotal)),
                0
            );
            subTotalShow = subTotal - (subTotal * discountValue) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        } else {
            setDiscountValue(e);
            subTotalShow = subTotal - (subTotal * e) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        }
    };

    const removeFormFieldSection = (i) => {
        let formValues = fieldSection.formValues;
        formValues.splice(i, 1);
        setFieldSection({ formValues });
    };

    const handleChangeSection = (i, e) => {
        let formValues = fieldSection.formValues;
        formValues[i][e.target.name] = e.target.value;
        setFieldSection({ formValues });
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const AssetsData = (e, assetString = '') => {
        setAssetsLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets?client_id=${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                let optionAssets = [];
                if (res.data !== undefined) {
                    {
                        res.data.client_assets &&
                            res.data.client_assets.map((asset) => {
                                optionAssets.push({
                                    value: asset.id,
                                    label: asset.name,
                                });
                            });
                    }
                }
                if (assetString == '' || !assetString) {
                    assetString = assetIdString;
                }
                assetString = !assetString ? '' : assetString;
                let assetIdList = assetString.split(',');
                const filterAssets = optionAssets.filter(item => assetIdList.includes(String(item.value)));
                
                setAssetId(filterAssets);
                setAssetData(optionAssets);
                setAssetsLoader(false);
            });
    };

    const getAllBanks = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    //   setAllBanks(res.invoice.bank_accounts);
                    setAllBanks(res.data.bank_accounts);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    const singleClientdata = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setClients(res.data.client_address);
            });
    };

    function handleAssets(selectedcate) {
        if (!selectedcate) {
            selectedcate = [];
        }
        setAssetId(selectedcate)
    }

    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const clientDetail = (e) => {
        setSelectClientId(e.id);
        setClientNameData(e.legal_name);
        setClientData(e.address);
        setWorkData(e.address);
        setClients(e);
        // getSingleClientsAddress(e.id);
        AssetsData(e.id);
        setPaymentOption(e.payment_option_id);
        setRateValue(e.rate);
    };

    const clientDetail2 = (e) => {
        setSelectClientId2(e);
        let index = allClients.findIndex((x) => x.id == e);
        if (index != -1) {
            setClient2(allClients[index].name);
        }
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.product_categories);
            });
    };

    // Product Categories/Set Categories
    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }

    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    const openNew = (e) => {
        if (e.target.value == "new") {
            setMod(true);
        }
    };

    const getallClients = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllClients(res.data.clients.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getAssignedUser1 = (id, name) => {
        setAssignedUserId1(id);
        setAssignedUserName1(name);
    };

    const getAgentUser = (id, name) => {
        setAgentUserId(id);
        setAgentUserName(name);
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };

    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };

    const getReferences = (type) => {
        setAllRef([]);
        if (type == "Work Estimate") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/references?type=Work Estimate`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        setAllRef(res.data.reference);
                        res.data.reference?.map((item) => {
                            if (item.by_default === "1") {
                                setDefaultRef(item.prefix);
                            }
                        });
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else if (type == "Work Order") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/references?type=Work Order`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        setAllRef(res.data.reference);
                        res.data.reference?.map((item) => {
                            if (item.by_default === "1") {
                                setDefaultRef(item.prefix);
                            }
                        });
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/references?type=Work Delivery Note`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        setAllRef(res.data.reference);
                        res.data.reference?.map((item) => {
                            if (item.by_default === "1") {
                                setDefaultRef(item.prefix);
                            }
                        });
                    } else {
                        //   setError(res.data.message);
                    }
                });
        }
    };

    const deleteTechnicalService = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-tables/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    history.push("/estimates");
                }
            });
    };

    const getDuplicate = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-tables/duplicate_service`,
                {
                    id: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/technical-service/${res.data.data.id}`);
                }
            });
    };

    const getprint = () => {
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const getTemplate = (type) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates?type=${type}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setTemplateData(res.data.templates);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getDownloadPDF = () => {
        setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email])
                    setIsDownloadModal(true);
                }
            });
    };

    const getDownloadPDF2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        formData.append("download", "1");

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    responseType: "arraybuffer",
                }
            )
            .then(function (res) {
                if (res.data) {
                    var blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    if (saleData.reference_type == "Work Estimate") {
                        fileSaver.saveAs(
                            blob,
                            `WORKESTIMATE${refNum}${clientNameData}.pdf`
                        );
                    } else if (saleData.reference_type == "Work Order") {
                        fileSaver.saveAs(blob, `WORKORDER${refNum}${clientNameData}.pdf`);
                    } else {
                        fileSaver.saveAs(
                            blob,
                            `WORKDELIVERYNOTE${refNum}${clientNameData}.pdf`
                        );
                    }
                }
                setIsDownloadModal(false);
            });
    };

    const getSendEmail = () => {
        setLink("");
        setIsSendModal(true);
        const formData = new FormData();
        formData.append("format", mailPDFFormat);
        formData.append("template_id", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email])
                }
            });
    };

    const getSendEmail2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("send_to", email);
        formData.append("cc", email2);
        formData.append("body", messageData);
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                    setIsSendModal(false);
                }, 3000);
            });
    };

    const getGenerate = (type) => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/generates`,
                {
                    id: userId,
                    from_type: saleData.reference_type,
                    to_type: type,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    if (type == "Normal Invoice") {
                        history.push(
                            `/invoicing-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                    } else {
                        history.push(
                            `/technical-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };

    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("name", selectedName);
        formData.append("type", "technical_table_attachments");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };

    function handleSelectCategoryAll(selectedcate) {
        setSelectedCates(selectedcate.value);
        // if (selectedcate.value === 1) {
        //     setBank(true);
        // } else {
        //     setBank(false);
        // }
    }

    const getDefaultPdf = (type) => {
        // setDefaultSend([]);
        if (type == "Work Estimate") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-default-pdf?type=Work Estimate`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        // setDefaultSend(res.data.data);
                        setFormat(res.data.data.format);
                        setMailingFormat(res.data.data.mailing_format);
                        setPriceTax(res.data.data.price_after_tax);
                        setProductService(res.data.data.include_main_image);
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else if (type == "Work Order") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-default-pdf?type=Work Order`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        // setDefaultSend(res.data.data);
                        setFormat(res.data.data.format);
                        setMailingFormat(res.data.data.mailing_format);
                        setPriceTax(res.data.data.price_after_tax);
                        setProductService(res.data.data.include_main_image);
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-default-pdf?type=Work Delivery Note`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        // setDefaultSend(res.data.data);
                        setFormat(res.data.data.format);
                        setMailingFormat(res.data.data.mailing_format);
                        setPriceTax(res.data.data.price_after_tax);
                        setProductService(res.data.data.include_main_image);
                    } else {
                        //   setError(res.data.message);
                    }
                });
        }
    };

    const getExport = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-tables/estimate-export`,
                {
                    ids: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };

    const getPrintOption = () => {
        setLink1("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink1(res.data.url);
                    setIsPrintModal(true);
                }
            });
    };

    const getPrintOption2 = () => {
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const getMessage = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-message`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    const originalString = res.data.settings.email_configuration_client_emails_message
                    setMessageData(originalString.replace(/\n/g, '<br />'));
                    const html =
                        res.data.settings.email_configuration_client_emails_message;
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        // getReferences()
        productCategories();
        getAssignUser();
        getProducts();
        getallClients();
        getAllBanks();
        getMessage();
    }, []);

    return (
        <React.Fragment>
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
                selectedName={selectedName}
            />
            {isDownloadModal ? (
                <DownloadOption
                    isDownloadModal={isDownloadModal}
                    setIsDownloadModal={setIsDownloadModal}
                    link={link}
                    setLink={setLink}
                    type={saleData.reference_type}
                    id={userId}
                    refNum={refNum}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getDownloadPDF={getDownloadPDF}
                    getDownloadPDF2={getDownloadPDF2}
                    templateData={templateData}
                    setSignedBox={setSignedBox}
                />
            ) : (
                ""
            )}
            {isSendModal ? (
                <SendEmail
                    isOpenModal={isSendModal}
                    setIsOpenModal={setIsSendModal}
                    link={link}
                    // setLink={setLink}
                    //   id={ids}
                    setEmail2={setEmail2}
                    setEmail={setEmail}
                    email={email}
                    email2={email2}
                    setFormat={setMailPDFFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getSendEmail={getSendEmail}
                    getSendEmail2={getSendEmail2}
                    success={success}
                    error={error}
                    // emailMessage={emailMessage}
                    type={saleData.reference_type}
                    // setEmailMessage={setEmailMessage}
                    messageData={messageData}
                    setMessageData={setMessageData}
                    format={mailPDFFormat}
                    mailingFormat={mailingFormat}
                    priceTax={priceTax}
                    productService={productService}
                    setSignedBox={setSignedBox}
                    id={userId}
                />
            ) : (
                ""
            )}
            {
                format && 
                <PrintOption
                    isPrintModal={isPrintModal}
                    setIsPrintModal={setIsPrintModal}
                    link={link1}
                    setLink={setLink1}
                    type={saleData.reference_type}
                    id={userId}
                    refNum={refNum}
                    pdfType={'Work Estimate'}
                    format={format}
                    template1={template1}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getPrintOption={getPrintOption}
                    getPrintOption2={getPrintOption2}
                    templateData={templateData}
                    setSignedBox={setSignedBox}
                />
            }
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Yes, delete it!")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        deleteTechnicalService(userId);
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title("Deleted");
                        setdynamic_description("El Documento fue eliminado.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
            ) : null}
            <div className="page-content">
                <Modal size="lg" isOpen={assetModal} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            New Asset
                        </h5>
                        <button
                            type="button"
                            className="cross__btn btn btn-primary waves-effect waves-light"
                            onClick={() => setassetModal(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <AssetsNew
                            clientData={clients}
                            modalData={assetModal}
                            setassetModal={setassetModal}
                            AssetsData={AssetsData}
                        />
                    </div>
                </Modal>
                {isOpenModalNew ? (
                    <NewPayment
                        setIsOpenModal={setIsOpenModalNew}
                        NewpaymentShow={NewpaymentShow}
                        setPaymentOption={setPaymentOption}
                    />
                ) : (
                    ""
                )}
                {deliveryOptionModal ? (
                    <DeliveryOptionModal
                        setDeliveryOptionModal={setDeliveryOptionModal}
                        getDeliveryOption={getDeliveryOption}
                    />
                ) : (
                    ""
                )}
                {selectClientModal ? (
                    <ClientList
                        setSelectClientModal={setSelectClientModal}
                        setSelectClientId={setSelectClientId}
                        clients={allClients}
                        clientDetail={clientDetail}
                        getClients={getClients}
                    />
                ) : (
                    ""
                )}
                {selectClientModal2 ? (
                    <ClientList
                        setSelectClientModal={setSelectClientModal2}
                        setSelectClientId={setSelectClientId2}
                        clients={allClients}
                        clientDetail={clientDetail2}
                    />
                ) : (
                    ""
                )}
                {assignedModal ? (
                    <AssignUserList
                        setAssignedModal={setAssignedModal}
                        getAssignedUser={getAssignedUser}
                        data={assignUsers}
                    />
                ) : (
                    ""
                )}
                <MetaTags>
                    <title>Technical services | Clouget</title>
                </MetaTags>
                {isOpenAddress ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress}
                        clients={clients}
                        setClientData={setClientData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                {isWorkAddress ? (
                    <ClientWorkAddress
                        setIsOpenModal={setIsWorkAddress}
                        clients={clients}
                        setWorkData={setWorkData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                CreateSale(e, v);
                            }}
                            disabled={mainFormDisabled ? true : false}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">
                                            {" "}
                                            {saleData.generated_from == "" ? (
                                                `${props.t("Edit")} ${props.t(saleData.reference_type)}`
                                            ) : saleData.generated_from ? (
                                                <>
                                                    {props.t(saleData.generated_from)}{" "}
                                                    {saleData.generated_id}
                                                </>
                                            ) : (
                                                `${props.t("Edit Technical service")}`
                                            )}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <span
                                                className="btn btn-primary"
                                                onClick={() => backPage()}
                                            >
                                                {props.t("Back")}
                                            </span>
                                            <div className="btn-group me-2">
                                                <MDBContainer>
                                                    <MDBDropdown>
                                                        <MDBDropdownToggle
                                                            className="btn btn-primary"
                                                            type="button"
                                                        >
                                                            {props.t("More")}
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getDownloadPDF()}
                                                                >
                                                                    {props.t("Download as pdf")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getprint()}
                                                                >
                                                                    {props.t("Print")}
                                                                </span>
                                                                <ul className="dropdown-menu dropdown-submenu">
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getprint()}
                                                                        >
                                                                            {props.t("Default View")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getPrintOption()}
                                                                        >
                                                                            {props.t("More options")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                </ul>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setIsSendModal(true)}
                                                                >
                                                                    {props.t("Send")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getDuplicate()}
                                                                >
                                                                    {props.t("Duplicate")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setconfirm_alert(true);
                                                                    }}
                                                                >
                                                                    {props.t("Delete")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getExport()}
                                                                >
                                                                    {props.t("Export")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {props.t("Generate")}
                                                                </span>
                                                                <ul className="dropdown-menu dropdown-submenu">
                                                                    {saleData.reference_type ===
                                                                        "Work Estimate" ? (
                                                                        <>
                                                                            <MDBDropdownItem>
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        getGenerate("Work Order")
                                                                                    }
                                                                                >
                                                                                    {props.t("Work Order")}
                                                                                </span>
                                                                            </MDBDropdownItem>
                                                                            <MDBDropdownItem>
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        getGenerate("Work Delivery Note")
                                                                                    }
                                                                                >
                                                                                    {props.t("Delivery Notes")}
                                                                                </span>
                                                                            </MDBDropdownItem>
                                                                            <MDBDropdownItem>
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        getGenerate("Normal Invoice")
                                                                                    }
                                                                                >
                                                                                    {props.t("Invoice")}
                                                                                </span>
                                                                            </MDBDropdownItem>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {saleData.reference_type ===
                                                                                "Work Order" ? (
                                                                                <>
                                                                                    <MDBDropdownItem>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() =>
                                                                                                getGenerate(
                                                                                                    "Work Delivery Note"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {props.t("Delivery Notes")}
                                                                                        </span>
                                                                                    </MDBDropdownItem>
                                                                                    <MDBDropdownItem>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() =>
                                                                                                getGenerate("Normal Invoice")
                                                                                            }
                                                                                        >
                                                                                            {props.t("Invoice")}
                                                                                        </span>
                                                                                    </MDBDropdownItem>
                                                                                </>
                                                                            ) : (
                                                                                <MDBDropdownItem>
                                                                                    <span
                                                                                        className="dropdown-item"
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            getGenerate("Normal Invoice")
                                                                                        }
                                                                                    >
                                                                                        {props.t("Invoice")}
                                                                                    </span>
                                                                                </MDBDropdownItem>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </ul>
                                                            </MDBDropdownItem>
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                </MDBContainer>
                                            </div>
                                            {isEditable ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary  disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-primary " type="submit">
                                                            {props.t("Update")}
                                                        </button>
                                                    )}
                                                    <span
                                                        onClick={() => setIsEditable(false)}
                                                        className="btn btn-primary  ms-2"
                                                    >
                                                        {props.t("Cancel")}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {getPermission().work_estimate.work_estimate.edit
                                                        .is_checked === 1 ? (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary "
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                            <DefaultPrintButtons
                                                getprint={getprint}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            {error4 ? <Alert color="danger">{props.t(error4)}</Alert> : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}:</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        value={
                                                            saleData.reference !== null
                                                                ? saleData.reference
                                                                : ""
                                                        }
                                                        name="reference"
                                                        onChange={(e) => getType(e)}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <Input
                                                            className="w-100 quantity"
                                                            type="number"
                                                            value={refNum !== null ? refNum : ""}
                                                            //   value={saleData && saleData.reference_number}
                                                            step="1"
                                                            min="1"
                                                            name="reference_number"
                                                            //   placeholder={
                                                            //     referenceType === "pro" ? "Automatic" : ""
                                                            //   }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <label>{props.t("Client")}</label>
                                                <div className="d-flex">
                                                    <div className="pe-2 w-100 client_custom-dropdown">
                                                        <ClientCustomDropdown
                                                            data={allClients}
                                                            getClients={getClients}
                                                            getClientData={getClientData}
                                                            clientLoader={clientLoader}
                                                            setMainFormDisabled={setMainFormDisabled}
                                                            setClientSelect={setClientSelect}
                                                            setClientAddress={setClientAddress}
                                                            setClientFull={setClientFull}
                                                            clientNameData={clientNameData}
                                                            setClientNameData={setClientNameData}
                                                            clientDetail={clientDetail}
                                                            getAssests={AssetsData}
                                                            isEditable={isEditable}
                                                            paginationData={paginationData}
                                                            paginateData={paginateData}
                                                        />
                                                    </div>
                                                    <div className="pe-2">
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={
                                                                isEditable
                                                                    ? () => {
                                                                        setSelectClientModal(true),
                                                                            getClientData();
                                                                    }
                                                                    : () => console.log("")
                                                            }
                                                            disabled={isEditable ? false : true}
                                                            type="button"
                                                        >
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </div>
                                                    <Link
                                                        className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                                                            }`}
                                                        to={`/client/${selectClientId}`}
                                                        target="_blank"
                                                    >
                                                        <i className="fas fa-eye" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Payment Option")}</label>
                                                <div className="d-flex justify-content-between">
                                                    {paymentLoader ? (
                                                        <div className="pe-2">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                            ></Spinner>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="w-100 pe-2">
                                                        <AvField
                                                            type="select"
                                                            name="payment_option"
                                                            disabled={isEditable ? false : true}
                                                            onChange={(e) =>
                                                                handleSelectCategoryAll(e.target.value)
                                                            }
                                                            value={
                                                                paymentOption !== null ? paymentOption : ""
                                                            }
                                                        //   value={saleData.payment_option}
                                                        >
                                                            <option value="" hidden>
                                                                {props.t("Select")}
                                                            </option>
                                                            {paymentData &&
                                                                paymentData.map((item, i) => (
                                                                    <option
                                                                        value={item.id}
                                                                        key={i}
                                                                    // label={item.name}
                                                                    >
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => setIsOpenModalNew(true)}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        type="button"
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        + {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                            {bank ? (
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Bank Account")}
                                                        readOnly={isEditable ? false : true}
                                                        type="select"
                                                        name="bank_account"
                                                        value={
                                                            saleData.bank_account !== null
                                                                ? saleData.bank_account
                                                                : ""
                                                        }
                                                    >
                                                        <option value="no_account">
                                                            {props.t("Without bank account")}
                                                        </option>
                                                        {allBanks &&
                                                            allBanks.map((bank, i) => (
                                                                <option value={bank.id} key={i}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="mb-3">
                                                {/* <AvField
                                                    label={props.t("Title")}
                                                    type="text"
                                                    name="title"
                                                    value={saleData.title !== null ? saleData.title : ""}
                                                    readOnly={isEditable ? false : true}
                                                /> */}
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label>{props.t("Assets")}:</label>
                                                        <div className="d-flex justify-content-between">
                                                            {assetsLoader ? (
                                                                <div className="pe-2">
                                                                    <Spinner
                                                                        animation="border"
                                                                        role="status"
                                                                    ></Spinner>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="w-100 pe-2">
                                                                <Select
                                                                    options={assetData}
                                                                    value={assetId}
                                                                    onChange={handleAssets}
                                                                    name="assets"
                                                                    isMulti
                                                                    isDisabled={isEditable ? false : true}
                                                                />
                                                                {/* <AvField
                                                                    type="select"
                                                                    name="assets"
                                                                    value={
                                                                        saleData.asset_id !== null
                                                                            ? saleData.asset_id
                                                                            : ""
                                                                    }
                                                                    disabled={isEditable ? false : true}
                                                                >
                                                                    <option value="" hidden>
                                                                        {props.t("Select")}
                                                                    </option>
                                                                    {assetData &&
                                                                        assetData.map((item, i) => (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </AvField> */}
                                                            </div>
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                onClick={() => setassetModal(true)}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + {props.t("New")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Date")}</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(e) => setStartDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    name="date"
                                                    // value={saleData && saleData.date}
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Status")}
                                                    type="select"
                                                    name="status"
                                                    value={
                                                        saleData.status !== null ? saleData.status : ""
                                                    }
                                                    disabled={isEditable ? false : true}
                                                >
                                                    {JSON.parse(localStorage.getItem("CustomStates"))?.map((item) => item.name === saleData.reference_type ?
                                                        <>
                                                            {item.states?.map((option) =>
                                                                <option value={option.name} key={option.id}>{props.t(option.name)}</option>
                                                            )}
                                                        </>
                                                        :
                                                        ""
                                                    )}
                                                    {/* <option value="" hidden>
                            {props.t("Select")}
                          </option>
                          {saleData && saleData.reference == "WE" ? (
                            <>
                              <option value="pending">
                                {props.t("Pending")}
                              </option>
                              <option value="refused">
                                {props.t("Refused")}
                              </option>
                              <option value="accepted">
                                {props.t("Accepted")}
                              </option>
                              <option value="closed">
                                {props.t("Closed")}
                              </option>
                            </>
                          ) : (
                            <>
                              {saleData && saleData.reference == "WO" ? (
                                <>
                                  <option value="pending">
                                    {props.t("Pending")}
                                  </option>
                                  <option value="refused">
                                    {props.t("Refused")}
                                  </option>
                                  <option value="In progress">
                                    {props.t("In Progress")}
                                  </option>
                                  <option value="closed">Closed</option>
                                </>
                              ) : (
                                // saleData && saleData.reference == "WDN"
                                <>
                                  <option value="pending invoice">
                                    {props.t("Pending Invoice")}
                                  </option>
                                  <option value="In Progress">
                                    {props.t("In Progress")}
                                  </option>
                                  <option value="Closed">
                                    {props.t("Closed")}
                                  </option>
                                  <option value="Invoiced">
                                    {props.t("Invoiced")}
                                  </option>
                                </>
                              )}
                            </>
                          )} */}
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Created by")}:</label>

                                                <span
                                                    onClick={() => setAssignedModal1(true)}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                        backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        pointerEvents: isEditable ? "" : "none",
                                                    }}
                                                >
                                                    {assignedUserName1}
                                                </span>
                                                {/* </input> */}
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Agent")}</label>
                                                <span
                                                    onClick={() => setAssignedModal2(true)}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                        backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        pointerEvents: isEditable ? "" : "none",
                                                    }}
                                                >
                                                    {agentUserName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Rate")}
                                                    type="select"
                                                    name="rate"
                                                    // value={saleData && saleData.rate}
                                                    value={rateValue !== "" ? rateValue : saleData.rate}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    {allRates?.map((item, i) => (
                                                        <option value={item.name} key={i}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Items")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("More Information")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "5",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("5");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Signature")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "6",
                                                })} `}
                                                onClick={() => {
                                                    toggleCustom("6");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1" className="table-responsive">
                                            <Row>
                                                <Col md={3} className="p-0">
                                                    <div className="button-section">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            onClick={() => addFormFields()}
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            + &nbsp; {props.t("New Item")}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0 ">
                                                    <div className="table-responsive m-height250">
                                                        <table className="table prod_data" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{props.t("Reference")}</th>
                                                                    <th>{props.t("Name")}</th>
                                                                    <th>{props.t("Description")}</th>
                                                                    <th>
                                                                        {props.t("Base Price ($)")}
                                                                    </th>
                                                                    <th>{props.t("Quantity")}</th>
                                                                    <th>{props.t("Disc. %")}</th>
                                                                    <th>{props.t("Subtotal ($)")}</th>
                                                                    <th>{props.t("Tax")} %</th>
                                                                    <th>{props.t("Other Tax")}</th>
                                                                    <th>{props.t("Delete")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fields.formValues &&
                                                                    fields.formValues.map((element, i) => (
                                                                        <ProductTechEstimates
                                                                            i={i}
                                                                            key={i}
                                                                            data={element}
                                                                            fields={fields}
                                                                            clientId={selectClientId}
                                                                            setFields={setFields}
                                                                            discountValue={discountValue}
                                                                            metaDiscount={metaDiscount}
                                                                            isEditable={isEditable}
                                                                            setMainFormDisabled={setMainFormDisabled}
                                                                        />
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Row className="row pt-3 pb-2">
                                                        <Col>
                                                            <div
                                                                style={{
                                                                    borderBottom: "1px solid #ced4da",
                                                                }}
                                                            ></div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="">
                                                        <div className="d-flex justify-content-end pe-5 ms-2">
                                                            <div>
                                                                <label className="me-2">
                                                                    {props.t("Disc. %")}:
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        width: "70px",
                                                                    }}
                                                                    className="input-style"
                                                                    min="0"
                                                                    type="number"
                                                                    name="meta_discount"
                                                                    value={((totalDisc / (totalBasePrice + totalDisc)) * 100).toFixed(2)}
                                                                    disabled
                                                                    onChange={(e) => {
                                                                        setDiscountValue(e.target.value),
                                                                            metaDiscount(e.target.value);
                                                                    }}
                                                                ></input>
                                                            </div>
                                                            <div>
                                                                {" "}
                                                                <input
                                                                    style={{
                                                                        width: "70px",
                                                                    }}
                                                                    disabled
                                                                    value={totalDisc.toFixed(2)}
                                                                    className="ms-1 input-style"
                                                                    name="d_value"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="sub-total-div mt-5">
                                                        <div className="sub-total">
                                                            <h6>{props.t("Subtotal ($)")}:</h6>
                                                            <p>
                                                                {/* {subTotalShow
                                  ? subTotalShow.toFixed(2)
                                  : subTotal.toFixed(2)} */}
                                                                {totalBasePrice.toFixed(2)}
                                                            </p>
                                                        </div>
                                                        {totalDisc !== 0 || totalDisc !== NaN ? (
                                                            <div className="sub-total">
                                                                <h6>{props.t("Discount (%)")}:</h6>
                                                                <p>{totalDisc.toFixed(2)}</p>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="sub-total">
                                                            <h6>{props.t("IVA ($)")}:</h6>
                                                            <p>{totalTax.toFixed(2)}</p>
                                                        </div>
                                                        {otherTax !== 0 ? (
                                                            <div className="sub-total">
                                                                <h6>{props.t("IR ($)")}:</h6>
                                                                <p>{otherTax.toFixed(2)}</p>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="sub-total2">
                                                            <h6>{props.t("Total ($)")}:</h6>
                                                            <p>
                                                                {subTotalShow
                                                                    ? subTotalShow.toFixed(2)
                                                                    : subTotal.toFixed(2)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label>{props.t("Assets")}:</label>
                                                        <div className="d-flex justify-content-between">
                                                            {assetsLoader ? (
                                                                <div className="pe-2">
                                                                    <Spinner
                                                                        animation="border"
                                                                        role="status"
                                                                    ></Spinner>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="w-100 pe-2">
                                                                <Select
                                                                    options={assetData}
                                                                    value={assetId}
                                                                    onChange={handleAssets}
                                                                    name="assets"
                                                                    isMulti
                                                                    isDisabled={isEditable ? false : true}
                                                                />
                                                                {/* <AvField
                                                                    type="select"
                                                                    name="assets"
                                                                    value={
                                                                        saleData.asset_id !== null
                                                                            ? saleData.asset_id
                                                                            : ""
                                                                    }
                                                                    disabled={isEditable ? false : true}
                                                                >
                                                                    <option value="" hidden>
                                                                        {props.t("Select")}
                                                                    </option>
                                                                    {assetData &&
                                                                        assetData.map((item, i) => (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </AvField> */}
                                                            </div>
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                onClick={() => setassetModal(true)}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + {props.t("New")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {saleData && saleData.reference == "WO" ? (
                                                    <>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <div className="mb-3">
                                                                <label>{props.t("Assigned to")}</label>
                                                                <span
                                                                    onClick={() => setAssignedModal(true)}
                                                                    readOnly={isEditable ? false : true}
                                                                    className="form-control"
                                                                    style={{
                                                                        minHeight: "35px",
                                                                    }}
                                                                >
                                                                    {getCurrentUser().email}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <label className="me-2">
                                                                {props.t("Assigned Date")}:
                                                            </label>

                                                            <AvField
                                                                autoComplete="off"
                                                                name="assigned_date"
                                                                type="date"
                                                                value={
                                                                    saleData.assigned_date !== null ? saleData.assigned_date.split(' ')[0] : ""
                                                                }
                                                                className="form-control"
                                                                disabled={isEditable ? false : true}
                                                            />
                                                        </Col>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {saleData && saleData.reference == "WE" ? (
                                                    ""
                                                ) : (
                                                    <>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <label className="me-2">
                                                                {props.t("Start date")}:
                                                            </label>
                                                            <DatePicker
                                                                selected={startDate2}
                                                                onChange={(e) => setStartDate2(e)}
                                                                disabled={isEditable ? false : true}
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={new Date()}
                                                                name="start_date"
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <label className="me-2">
                                                                {props.t("End Date")}:
                                                            </label>
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(e) => setEndDate(e)}
                                                                disabled={isEditable ? false : true}
                                                                dateFormat="dd/MM/yyyy"
                                                                name="end_date"
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <label className="me-2">
                                                                {props.t("Due Date")}:
                                                            </label>
                                                            <DatePicker
                                                                selected={sentDate && sentDate.sent_date}
                                                                onChange={(e) => setScheduleSentDate(e)}
                                                                dateFormat="dd/MM/yyyy"
                                                                disabled={isEditable ? false : true}
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </Col>
                                                        <Col lg={6} md={6} className="mt-3">
                                                            <label>{props.t("Delivery Options")}</label>
                                                            <div className="d-flex justify-content-between">
                                                                {deliveryOptionLoader ? (
                                                                    <div className="pe-2">
                                                                        <Spinner
                                                                            animation="border"
                                                                            role="status"
                                                                        ></Spinner>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <div className="w-100 pe-2">
                                                                    <AvField
                                                                        type="select"
                                                                        name="delivery_option"
                                                                        value={
                                                                            saleData.delivery_option !== null
                                                                                ? saleData.delivery_option
                                                                                : ""
                                                                        }
                                                                        disabled={isEditable ? false : true}
                                                                    >
                                                                        {deliveryOption &&
                                                                            deliveryOption.map((item, i) => (
                                                                                <option value={item.id} key={i}>
                                                                                    {item.name}
                                                                                </option>
                                                                            ))}
                                                                    </AvField>
                                                                </div>
                                                                <button
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    onClick={() => setDeliveryOptionModal(true)}
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                    type="button"
                                                                    disabled={isEditable ? false : true}
                                                                >
                                                                    + {props.t("New")}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="inv_address"
                                                        label={props.t("Inv. Address")}
                                                        value={clientData !== null ? clientData : ""}
                                                        onClick={() => setIsOpenAddress(true)}
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="work_address"
                                                        label={props.t("Del. Address")}
                                                        value={workData !== null ? workData : ""}
                                                        onClick={() => setIsWorkAddress(true)}
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <label className="me-2">
                                                        {props.t("Email Sent date")}:
                                                    </label>
                                                    <DatePicker
                                                        selected={emailDate}
                                                        onChange={(e) => setEmailDate(e)}
                                                        disabled={isEditable ? false : true}
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={new Date()}
                                                        // value={saleData && saleData.email_sent_date}
                                                        name="email_sent_date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    {saleData && saleData.reference == "WE" ? (
                                                        <>
                                                            <label className="me-2">
                                                                {props.t("Valid Until")}:
                                                            </label>
                                                            <DatePicker
                                                                selected={validDate}
                                                                onChange={(e) => setValidDate(e)}
                                                                disabled={isEditable ? false : true}
                                                                dateFormat="dd/MM/yyyy"
                                                                value={
                                                                    saleData.valid_until !== null
                                                                        ? saleData.valid_until
                                                                        : ""
                                                                }
                                                                name="valid_until"
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        onClick={() => setSelectClientModal2(true)}
                                                        type="text"
                                                        name="invoice_to"
                                                        label={props.t("Invoice to")}
                                                        disabled={isEditable ? false : true}
                                                        value={client2 !== null ? client2 : ""}
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3"></Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="select"
                                                        name="currency"
                                                        value={
                                                            saleData.currency !== null
                                                                ? saleData.currency
                                                                : ""
                                                        }
                                                        label={props.t("Currency")}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        <option value="inr">INR</option>
                                                        <option value="usd">USD</option>
                                                        <option value="gbp">GBP</option>
                                                    </AvField>
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="currency_rate"
                                                        label={props.t("Currency Rate")}
                                                        value={
                                                            saleData.currency_rate !== null
                                                                ? saleData.currency_rate
                                                                : ""
                                                        }
                                                        disabled={isEditable ? false : true}
                                                    // value={saleData && saleData.currency_rate}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3 mt-3">
                                                        <AvField
                                                            label={props.t("Comments")}
                                                            type="textarea"
                                                            name="comments"
                                                            value={
                                                                saleData.comments !== null
                                                                    ? saleData.comments
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                            value={
                                                                saleData.private_comments !== null
                                                                    ? saleData.private_comments
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="mt-3">
                                                    <AvField
                                                        label={props.t("Addendum")}
                                                        type="textarea"
                                                        name="addendum"
                                                        value={
                                                            saleData.addendum !== null
                                                                ? saleData.addendum
                                                                : ""
                                                        }
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <div>
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value={props.t("Web")}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <TabPane tabId="attachments">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="attach_btn">
                                                            <div
                                                                className="float-end d-flex btn btn-primary ms-2"
                                                                onClick={() => setMods(true)}
                                                            >
                                                                {props.t("New")}
                                                            </div>
                                                            <div
                                                                className="float-end d-flex btn btn-primary"
                                                                onClick={() => getSendAttachment()}
                                                            >
                                                                {props.t("Send via email")}
                                                            </div>{" "}
                                                            {error2 ? (
                                                                <Alert color="danger">{props.t(error2)}</Alert>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div>
                                                                <Table striped className="align-middle mt-5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>{props.t("Name")}</th>
                                                                            <th>{props.t("Description")}</th>
                                                                            <th>{props.t("Upload Date")}</th>
                                                                            <th>{props.t("Activity")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {attachDocument &&
                                                                            attachDocument.map((item, i) => (
                                                                                <TechnicalTablesAttachment
                                                                                    data={item}
                                                                                    key={i}
                                                                                    selectedIds={selectedIds}
                                                                                    setSelectedIds={setSelectedIds}
                                                                                    selectedName={selectedName}
                                                                                    setSelectedName={setSelectedName}
                                                                                />
                                                                            ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <Modal size="md" isOpen={mods} centered={true}>
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title mt-0"
                                                        id="myLargeModalLabel"
                                                    >
                                                        {props.t("New Document")}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="cross__btn btn btn-primary waves-effect waves-light"
                                                        onClick={() => setMods(false)}
                                                        aria-hidden="true"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                                <form onSubmit={(e) => createAttachment(e)}>
                                                    <Card>
                                                        <CardBody>
                                                            <div className="modal-body p-0">
                                                                <Row>
                                                                    <Col lg={12} md={12} className="mt-3">
                                                                        <div className="mb-3 required">
                                                                            <label>{props.t("Document")}</label>
                                                                            <input
                                                                                type="file"
                                                                                className="ms-3"
                                                                                name="document"
                                                                                onChange={filePreview}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={12} md={12} className="mt-3">
                                                                        <div className="mb-3">
                                                                            <AvField
                                                                                label={props.t("Description")}
                                                                                type="textarea"
                                                                                name="description"
                                                                            // value={saleData && saleData.description}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <div className="text-end">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="submit"
                                                                    >
                                                                        {props.t("Submit")}
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-primary ms-2"
                                                                        type="button"
                                                                        onClick={() => setMods(false)}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </form>
                                            </Modal>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Name")}
                                                            type="text"
                                                            name="name"
                                                            value={
                                                                saleData.name !== null ? saleData.name : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                    <div className="mb-3 required">
                                                        <AvField
                                                            label={props.t("Ced/Ruc")}
                                                            type="number"
                                                            onKeyPress={(e) => {
                                                                if (e.target.value.length == 13)
                                                                    e.preventDefault();
                                                            }}
                                                            name="tin"
                                                            value={
                                                                clients.tin !== null
                                                                    ? clients.tin
                                                                    : saleData.tin
                                                            }
                                                            disabled={isEditable ? false : true}
                                                            required="required"
                                                        />
                                                    </div>
                                                    <div className="company_img img-flex">
                                                        <label>{props.t("Main image")}</label>
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImg(e)}
                                                            disabled={isEditable ? false : true}
                                                            type="file"
                                                            id="comImg"
                                                            defaultValue={saleData && saleData.imgPreview}
                                                        />
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            <img src={imgPreview} />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    {assignedModal1 ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal1}
                            getAssignedUser={getAssignedUser1}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}

                    {assignedModal2 ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal2}
                            getAssignedUser={getAgentUser}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(EditTechnicalTable);
EditTechnicalTable.propTypes = {
    t: PropTypes.any,
};
