/* eslint-disable use-isnan */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Table,
    Spinner,
    Modal,
    Input,
} from "reactstrap";
// import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import CategoryModal from "../../Category/category-modal";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker";
import uploadImg from "../../../assets/images/def-img.svg";
import Loader from "../../../components/Loader";
import ClientAddress from "../../../components/ClientAddresses";
import ProductTechEstimates from "../../../components/Product/purchase";
import ClientWorkAddress from "../../../components/ClientAddresses/workAddress";
import NewPayment from "../../../components/PaymentOption/payment_option";
import ClientList from "../../../components/supplierList";
import AssetsNew from "../../../components/Assests/assests-new";
import { Link } from "react-router-dom";
import DeliveryOptionModal from "../../../components/DeliveryOption/modal";
import AssignUserList from "../../../components/AssignUserList";
import SupplierCustomDropdown from "../../../components/SupplierDropdown";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import DownloadOption from "../../Invoicing/download";
import SendEmail from "../../../pages/sendEmail";
import fileSaver from "file-saver";
import AllAttachment from "../../../components/Attachments/client-assets-attachment";
import SendAttachment from "../../SendAttachments/index";
import { useHistory } from "react-router-dom";
import PrintOption from "../../MorePrintOption";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import moment from "moment";
import CreateTrialModal from "../../plans/trialModal";
import DefaultPrintButtons from "../../../components/DefaultPrintButtons";
let totalAmount;
let subTotal;
let subTotalShow;
let dValue;
let totalBasePrice;
let totalDisc;
let totalTax;
let otherTax;

const PurchaseOrderEdit = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [poId] = useState(parseInt(id, 10));
    const [data, setData] = useState([]);
    const [bank, setBank] = useState(false);
    const [newDisable, setNewDisable] = useState(false);
    const [clients, setClients] = useState("");
    const [workData, setWorkData] = useState("");
    const [clientNameData, setClientNameData] = useState("");
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isWorkAddress, setIsWorkAddress] = useState(false);
    const [allClients, setAllClients] = useState([]);
    const [clientData, setClientData] = useState("");
    const [assetData, setAssetData] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [selectedCate, setSelectedCate] = useState("");
    const [assetId, setAssetId] = useState("");
    const [sentDate, setSentDate] = useState("");
    const [startDate, setStartDate] = useState("");
    // const [startDate2, setStartDate2] = useState("");
    // const [endDate, setEndDate] = useState("");
    const [emailDate, setEmailDate] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("po");
    const [discountValue, setDiscountValue] = useState(0);
    const [paymentData, setPaymentData] = useState("");
    const [isOpenModalNew, setIsOpenModalNew] = useState(false);
    const [assetModal, setassetModal] = useState(false);
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [selectClientModal2, setSelectClientModal2] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [selectClientId2, setSelectClientId2] = useState("");
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [selectedCates, setSelectedCates] = useState("");
    const [deliveryOption, setDeliveryOption] = useState("");
    const [deliveryOptionModal, setDeliveryOptionModal] = useState(false);
    const [deliveryOptionLoader, setDeliveryOptionLoader] = useState(false);
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");
    const [client2, setClient2] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedModal2, setAssignedModal2] = useState(false);
    const [assignedUserName, setAssignedUserName] = useState("");
    const [assignedUserId, setAssignedUserId] = useState("");
    const [agentUserName, setAgentUserName] = useState("");
    const [agentUserId, setAgentUserId] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [purchaseOrder, setPurchaseOrder] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [allBanks, setAllBanks] = useState([]);
    const [clientLoader, setClientLoader] = useState(false);
    const [mainFormDisabled, setMainFormDisabled] = useState(false);
    const [timer, setTimer] = useState(null);
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [refType, setRefType] = useState("");
    const [isDownloadModal, setIsDownloadModal] = useState("");
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [tinValue, setTinValue] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [template1, setTemplate1] = useState("");
    const [priceTax, setPriceTax] = useState("");
    const [mailingFormat, setMailingFormat] = useState("");
    const [productService, setProductService] = useState("");
    const [defaultConfig, setDefaultConfig] = useState("");
    const [email, setEmail] = useState(getCurrentUser().email);
    const [email2, setEmail2] = useState("");
    const [isSendModal, setIsSendModal] = useState(false);
    const [link, setLink] = useState("");
    const [format, setFormat] = useState("");
    const [attachDocument, setAttachDocument] = useState([]);
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [error4, setError4] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [fields, setFields] = useState({
        formValues: [],
    });
    const [isPrintModal, setIsPrintModal] = useState(false);
    const [link1, setLink1] = useState("");
    const [mailPDFFormat, setMailPDFFormat] = useState("");

    let params = new URLSearchParams(window.location.search);
    const [generate] = useState(params.get("generate"));
    const [messageData, setMessageData] = useState("");
    const [signedBox, setSignedBox] = useState("");
    const [defaultDeliveyOption, setDefaultDeliveryOption] = useState({})
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const getSuppliers = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };
    const createAttachment = (e, value) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("document", document);
        formData.append("description", e.target.description.value);
        formData.append("type", "attachments");
        formData.append("purchase_id", poId);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    AllAttach();
                    setSuccess(res.data.message);
                    setIsOpenModals(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const AllAttach = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-attachments?purchase_id=${poId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAttachDocument(res.data.purchase_attachments);
            });
    };
    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };
    const productCreateSubmit = (e, value) => {

        setLoading(true);
        let setPaid = "1";
        if (value.set_as_paid !== "1") {
            setPaid = "0";
        } else {
            setPaid = "1";
        }
        const formData = new FormData();
        formData.append("reference", "PO");
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("supplier_id", selectClientId);
        formData.append("status", value.status !== null ? value.status : "");
        formData.append("title", value.title !== null ? value.title : "");
        formData.append(
            "payment_option",
            e.target.payment_option.value !== null
                ? e.target.payment_option.value
                : ""
        );
        // formData.append("payment_term", value.payment_term);
        formData.append("date", e.target.date.value);
        // formData.append("agent_id", value.agent_id);
        // formData.append("date", value.date !== null ? value.date : "");
        formData.append(
            "purchase_document_ref",
            value.purchase_document_ref !== null ? value.purchase_document_ref : ""
        );
        formData.append("name", value.name !== null ? value.name : "");
        formData.append("set_as_paid", setPaid);
        formData.append("delivery_option", e.target.delivery_option.value);
        formData.append(
            "private_comments",
            e.target.private_comments.value !== null ? e.target.private_comments.value : ""
        );

        formData.append("email_sent_date", e.target.email_sent_date.value);
        formData.append(
            "sent_date",
            value.sent_date !== null ? value.sent_date : ""
        );
        formData.append("currency", value.currency);
        formData.append("tin", value.tin);
        formData.append("currency_rate", e.target.currency_rate.value);
        formData.append("comments", value.comments !== null ? value.comments : "");
        formData.append(
            "private_comments",
            e.target.private_comments.value !== null ? e.target.private_comments.value : ""
        );
        formData.append("addendum", value.addendum !== null ? value.addendum : "");
        formData.append("meta_discount", e.target.meta_discount.value);
        formData.append("signature", image);
        formData.append("asset_id", assetId);
        formData.append("created_by", assignedUserId);
        formData.append("agent_id", agentUserId);
        formData.append("bank_account", value.bank_account);
        formData.append("item", JSON.stringify(fields.formValues));
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tables/${poId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                    if (res.data.message === "Ced/Ruc number is required") {
                        setCustomActiveTab("5");
                    }
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getSinglePurchaseOrder = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tables/${poId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setPurchaseOrder(res.data.data);
                    setRefNum(res.data.data.reference_number);
                    setSelectClientId(res.data.data.supplier_id);
                    setClientNameData(res.data.data.supplier_name);
                    setAssignedUserId(res.data.data.created_by);
                    setAssignedUserName(res.data.data.created_by_name);
                    setAgentUserId(res.data.data.agent_id);
                    setAgentUserName(res.data.data.agent_name);
                    setSelectedCates(res.data.data.payment_option);
                    setPaymentOption(res.data.data.payment_option);
                    setSelectedDeliveryOption(res.data.data.delivery_option);
                    getTemplate(res.data.data.reference_type);
                    setRefType(res.data.data.reference_type);
                    setDiscountValue(res.data.data.meta_discount);
                    const momentDate = moment(res.data.data.date, "DD-MM-YYYY");
                    setStartDate(momentDate.toDate());
                    setStartDate(momentDate.toDate());
                    if (res.data.data.email_sent_date !== null) {
                        const momentDate1 = moment(
                            res.data.data.email_sent_date,
                            "DD-MM-YYYY"
                        );
                        setEmailDate(momentDate1.toDate());
                    }
                    if (res.data.data.sent_date !== null) {
                        const momentDate2 = moment(res.data.data.sent_date, "DD-MM-YYYY");
                        setSentDate(momentDate2.toDate());
                    }
                    setFields({
                        formValues: res.data.data.items?.map((option) => ({
                            reference: option.reference,
                            reference_id: option.reference_id,
                            reference_number: option.product_reference_number,
                            name: option.name,
                            description: option.description,
                            base_price: option.base_price,
                            quantity: option.quantity,
                            discount: option.discount,
                            amount: option.amount_with_out_vat,
                            vat: option.vat,
                            tax: option.tax,
                            subtotal: option.subtotal,
                            discAmount: option.discAmount !== null ? option.discAmount : 0,
                            taxAmount: option.taxAmount,
                            otherTaxAmount: option.otherTaxAmount,
                            showPopup: option?.showPopup,
                            isUpdate: option?.isUpdate,
                        })),
                    });
                    setImgPreview(res.data.data.image);
                    if (res.data.data.payment_option === "1") {
                        setBank(true);
                    }
                }
            });
    };
    const getMessage = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-message`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    const originalString = res.data.settings.email_configuration_client_emails_message
                    setMessageData(originalString.replace(/\n/g, '<br />'));
                    const html =
                        res.data.settings.email_configuration_client_emails_message;
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getSinglePurchaseOrder();
        getMessage();
    }, []);

    const getAllBanks = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setAllBanks(res.data.bank_accounts);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getClientData = (e) => {
        setClientLoader(true);
        setAllClients([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/suppliers`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/suppliers?search=${e}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setAllClients(res.data.suppliers);
                } else {
                    setAllClients([]);
                    setError(res.data.message);
                }
                setClientLoader(false);
                setTimeout(() => {
                    setError("");
                    //   setSuccess("");
                }, 2000);
            });
    };

    useEffect(() => {
        getAllBanks();
        getClientData();
    }, []);
    const [fieldSection, setFieldSection] = useState({
        formValues: [{ message: "", cast: "" }],
    });

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    function handleSelectDeliveryOption(e) {
        setSelectedDeliveryOption(e.value);
    }

    const getDeliveryOption = () => {
        setDeliveryOptionLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/delivery-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDeliveryOption(res.data.delivery_options);
                res.data.delivery_options?.map((item) => {
                    if (item.by_default === "1") {
                        setDefaultDeliveryOption({ value: item.id, label: item.name });
                    }
                });
                setDeliveryOptionLoader(false);
            });
    };

    let selectDeliveryOption = [];
    if (deliveryOption !== undefined) {
        {
            deliveryOption &&
                deliveryOption.map((item) => {
                    selectDeliveryOption.push({
                        value: item.id,
                        label: item.name,
                    });
                });
        }
    }

    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    const getAgentUser = (id, name) => {
        setAgentUserId(id);
        setAgentUserName(name);
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };

    useEffect(() => {
        NewpaymentShow();
        getAssignUser();
        getDeliveryOption();
    }, []);

    let optionClients = [];
    if (allClients !== undefined) {
        {
            allClients &&
                allClients.map((client) => {
                    optionClients.push({
                        value: client.id,
                        label: client.name,
                    });
                });
        }
    }

    let optionPayment = [];
    if (paymentData !== undefined) {
        {
            paymentData &&
                paymentData.map((payment) => {
                    optionPayment.push({
                        value: payment.id,
                        label: payment.name,
                    });
                });
        }
    }

    function handleSelectCategoryAll(selectedcate) {
        setSelectedCates(selectedcate.value);
        // if (selectedcate.value === 1) {
        //     setBank(true);
        // } else {
        //     setBank(false);
        // }
    }

    function handleAssets(selectedcate) {
        setAssetId(selectedcate.value);
    }

    const addFormFields = () => {
        setFields({
            formValues: [
                ...fields.formValues,
                {
                    reference: "",
                    reference_id: "",
                    name: "",
                    description: "",
                    base_price: "",
                    quantity: "1",
                    discount: "",
                    amount: "0",
                    vat: "0",
                    tax: "0",
                    subtotal: "0",
                    discAmount: "0",
                    taxAmount: "0",
                    otherTaxAmount: "0",
                },
            ],
        });
    };

    totalAmount = fields.formValues.reduce(
        (totalAmount, currentValue) =>
            (totalAmount = totalAmount + parseFloat(currentValue.amount)),
        0
    );

    subTotal = fields.formValues.reduce(
        (subTotal, currentValue) =>
            (subTotal = subTotal + parseFloat(currentValue.subtotal)),
        0
    );

    subTotalShow = subTotal - (subTotal * discountValue) / 100;
    dValue = (subTotal - subTotalShow).toFixed(2);
    totalDisc = fields.formValues.reduce(
        (totalDisc, currentValue) =>
            (totalDisc = totalDisc + parseFloat(currentValue.discAmount)),
        0
    );
    totalBasePrice = fields.formValues.reduce(
        (totalBasePrice, currentValue) =>
            (totalBasePrice = totalBasePrice + parseFloat(currentValue.amount)),
        0
    );
    totalTax = fields.formValues.reduce(
        (totalTax, currentValue) =>
            (totalTax = totalTax + parseFloat(currentValue.taxAmount)),
        0
    );
    otherTax = fields.formValues.reduce(
        (otherTax, currentValue) =>
            (otherTax = otherTax + parseFloat(currentValue.otherTaxAmount)),
        0
    );
    const metaDiscount = (e) => {
        if (e == undefined) {
            subTotal = fields.formValues.reduce(
                (subTotal, currentValue) =>
                    (subTotal = subTotal + parseFloat(currentValue.subtotal)),
                0
            );
            subTotalShow = subTotal - (subTotal * discountValue) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        } else {
            setDiscountValue(e);
            subTotalShow = subTotal - (subTotal * e) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        }
    };
    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const clientDetail = (e) => {
        setSelectClientId(e.id);
        setClientNameData(e.legal_name);
        setClientData(e.address);
        setClients(e);
        setTinValue(e.tin);
        getSingleClientsAddress(e.id);
        AssetsData(e.id);
        setPaymentOption(e.payment_option_id);
        setNewDisable(true);
        // setBank(true);
    };

    const clientDetail2 = (e) => {
        setSelectClientId2(e);
        let index = allClients.findIndex((x) => x.id == e);
        if (index != -1) {
            setClient2(allClients[index].name);
        }
    };

    const getSingleClientsAddress = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-addresses?client_id=${e}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setAllAddress(res.data.client_addresses)
            });
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.product_categories);
            });
    };

    const AssetsData = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets?client_id=${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAssetData(res.data.client_assets);
            });
    };

    let optionAssets = [];
    if (assetData !== undefined) {
        {
            assetData &&
                assetData.map((asset) => {
                    optionAssets.push({
                        value: asset.id,
                        label: asset.name,
                    });
                });
        }
    }

    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }

    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };

    const getAllClients = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/suppliers`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllClients(res.data.suppliers);
                } else {
                    setError(res.data.message);
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Purchase Order`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    const DeletePurchaseOrder = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tables/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    history.push("/purchase-order");
                }
            });
    };
    const getprint = () => {
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    const getTemplate = (type) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates?type=${type}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setTemplateData(res.data.templates);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getDownloadPDF = () => {
        setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email])
                    setIsDownloadModal(true);
                }
            });
    };
    const getDownloadPDF2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);
        formData.append("download", "1");

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    responseType: "arraybuffer",
                }
            )
            .then(function (res) {
                if (res.data) {
                    var blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    fileSaver.saveAs(blob, `PURCHASEORDER${refNum}${clientNameData}.pdf`);
                }
                setIsDownloadModal(false);
            });
    };
    const getSendEmail = () => {
        setLink("");
        setIsSendModal(true);
        const formData = new FormData();
        formData.append("format", mailPDFFormat);
        formData.append("template_id", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email])
                }
            });
    };
    const getSendEmail2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("send_to", email);
        formData.append("cc", email2);
        formData.append("body", messageData);
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                    setIsSendModal(false);
                }, 3000);
            });
    };
    const getGenerate = (type) => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/generates`,
                {
                    id: poId,
                    from_type: purchaseOrder.reference_type,
                    to_type: type,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    if (type == "Purchase Invoice") {
                        history.push(
                            `/purchase-invoice/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                    } else {
                        history.push(
                            `/purchase-delivery-Notes/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("type", "purchase_attachments");
        formData.append("name", selectedName);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };
    const getDefaultPdf = () => {
        setFormat("");
        setMailingFormat("");
        setPriceTax("");
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-default-pdf?type=Purchase Order`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    //   setPriceTax()
                    //   setDefaultSend(res.data.data);
                    setFormat(res.data.data.format);
                    setMailingFormat(res.data.data.mailing_format);
                    setPriceTax(res.data.data.price_after_tax);
                    setProductService(res.data.data.include_main_image);
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    const getExport = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tables-export`,
                {
                    ids: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getPrintOption = () => {
        setLink1("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink1(res.data.url);
                    setIsPrintModal(true);
                }
            });
    };
    const getPrintOption2 = () => {
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", poId);
        formData.append("type", purchaseOrder.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };
    useEffect(() => {
        getReferences();
        getDefaultPdf();
        productCategories();
        getProducts();
        getAllClients();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
                selectedName={selectedName}
            />
            {isDownloadModal ? (
                <DownloadOption
                    isDownloadModal={isDownloadModal}
                    setIsDownloadModal={setIsDownloadModal}
                    link={link}
                    setLink={setLink}
                    type={purchaseOrder.reference_type}
                    id={poId}
                    refNum={refNum}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getDownloadPDF={getDownloadPDF}
                    getDownloadPDF2={getDownloadPDF2}
                    setSignedBox={setSignedBox}
                    templateData={templateData}
                />
            ) : (
                ""
            )}
            {isSendModal ? (
                <SendEmail
                    isOpenModal={isSendModal}
                    setIsOpenModal={setIsSendModal}
                    link={link}
                    // setLink={setLink}
                    //   id={ids}
                    setEmail2={setEmail2}
                    setEmail={setEmail}
                    email={email}
                    email2={email2}
                    setSignedBox={setSignedBox}
                    setFormat={setMailPDFFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getSendEmail={getSendEmail}
                    getSendEmail2={getSendEmail2}
                    success={success}
                    error={error}
                    // emailMessage={emailMessage}
                    type={purchaseOrder.reference_type}
                    // setEmailMessage={setEmailMessage}
                    messageData={messageData}
                    setMessageData={setMessageData}
                    format={mailPDFFormat}
                    mailingFormat={mailingFormat}
                    priceTax={priceTax}
                    productService={productService}
                    id={poId}
                />
            ) : (
                ""
            )}
            {
                format &&
                <PrintOption
                    isPrintModal={isPrintModal}
                    setIsPrintModal={setIsPrintModal}
                    link={link1}
                    setLink={setLink1}
                    setSignedBox={setSignedBox}
                    type={purchaseOrder.reference_type}
                    id={poId}
                    refNum={refNum}
                    pdfType={'Purchase Order'}
                    format={format}
                    template1={template1}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getPrintOption={getPrintOption}
                    getPrintOption2={getPrintOption2}
                    templateData={templateData}
                />
            }
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Yes, delete it!")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        DeletePurchaseOrder(poId);
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title("Deleted");
                        setdynamic_description("El Documento fue eliminado.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
            ) : null}

            <div className="page-content">
                <MetaTags>
                    <title> Purchase Order| Clouget</title>
                </MetaTags>
                {isOpenAddress ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress}
                        clients={clients}
                        setClientData={setClientData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                {deliveryOptionModal ? (
                    <DeliveryOptionModal
                        setDeliveryOptionModal={setDeliveryOptionModal}
                        getDeliveryOption={getDeliveryOption}
                    />
                ) : (
                    ""
                )}
                {isWorkAddress ? (
                    <ClientWorkAddress
                        setIsOpenModal={setIsWorkAddress}
                        clients={clients}
                        setWorkData={setWorkData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                <CategoryModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getAllCategories={productCategories}
                />
                <Container fluid>
                    <div className="create_company">
                        {loader !== "" ? (
                            loader
                        ) : (
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    productCreateSubmit(e, v);
                                }}
                                disabled={mainFormDisabled ? true : false}
                            >
                                <div className="page-title-box">
                                    <Row className="align-items-center">
                                        <Col md={6}>
                                            <h6 className="page-title">
                                                {purchaseOrder.generated_from == "" ? (
                                                    `${props.t("Edit Purchase Order")}`
                                                ) : purchaseOrder.generated_from ? (
                                                    <>
                                                        {props.t(purchaseOrder.generated_from)}{" "}
                                                        {purchaseOrder.generated_id}
                                                    </>
                                                ) : (
                                                    `${props.t("Edit Purchase Order")}`
                                                )}
                                            </h6>
                                        </Col>
                                        <Col md={6}>
                                            <div className="float-end d-flex">
                                                <Link
                                                    type="button"
                                                    to="/purchase-order"
                                                    className="btn btn-primary"
                                                >
                                                    {props.t("Back")}
                                                </Link>
                                                <div className="btn-group me-2">
                                                    <MDBContainer>
                                                        <MDBDropdown>
                                                            <MDBDropdownToggle
                                                                className="btn btn-primary"
                                                                type="button"
                                                            >
                                                                {props.t("More")}
                                                            </MDBDropdownToggle>
                                                            <MDBDropdownMenu>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDownloadPDF()}
                                                                    >
                                                                        {props.t("Download as pdf")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getprint()}
                                                                    >
                                                                        {props.t("Print")}
                                                                    </span>
                                                                    <ul className="dropdown-menu dropdown-submenu">
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => getprint()}
                                                                            >
                                                                                {props.t("Default View")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => getPrintOption()}
                                                                            >
                                                                                {props.t("More options")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                    </ul>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setIsSendModal(true)}
                                                                    >
                                                                        {props.t("Send")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicate()}
                                                                    >
                                                                        {props.t("Duplicate")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setconfirm_alert(true);
                                                                        }}
                                                                    >
                                                                        {props.t("Delete")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getExport()}
                                                                    >
                                                                        {props.t("Export")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {props.t("Generate")}
                                                                    </span>
                                                                    <ul className="dropdown-menu dropdown-submenu">
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Purchase Delivery Note")
                                                                                }
                                                                            >
                                                                                {props.t("Purchase Delivery Notes")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Purchase Invoice")
                                                                                }
                                                                            >
                                                                                {props.t("Purchase Invoice")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                    </ul>
                                                                </MDBDropdownItem>
                                                            </MDBDropdownMenu>
                                                        </MDBDropdown>
                                                    </MDBContainer>
                                                </div>
                                                {isEditable ? (
                                                    <>
                                                        {loading ? (
                                                            <button
                                                                className="btn btn-primary  disabled"
                                                                type="button"
                                                                disabled
                                                            >
                                                                {props.t("Loading")}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary "
                                                                type="submit"
                                                            >
                                                                {props.t("Update")}
                                                            </button>
                                                        )}
                                                        <span
                                                            onClick={() => setIsEditable(false)}
                                                            className="btn btn-primary  ms-2"
                                                        >
                                                            {props.t("Cancel")}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {getPermission().purchase_orders.purchase_orders
                                                            .edit.is_checked === 1 ? (
                                                            <span
                                                                onClick={() => createNew()}
                                                                className="btn btn-primary "
                                                            >
                                                                {props.t("Edit")}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                )}
                                                <DefaultPrintButtons
                                                    getprint={getprint}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                                {error4 ? <Alert color="danger">{props.t(error4)}</Alert> : null}
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label>{props.t("Reference")}:</label>
                                                    <div className="d-flex">
                                                        <AvField
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            type="select"
                                                            name="reference"
                                                            value={
                                                                purchaseOrder.reference !== null
                                                                    ? purchaseOrder.reference
                                                                    : ""
                                                            }
                                                            onChange={(e) => getType(e)}
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            {allRef &&
                                                                allRef.map((item, i) => (
                                                                    <option value={item.prefix} key={i}>
                                                                        {item.prefix}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                        <div className="w-100 ps-3">
                                                            <Input
                                                                className="w-100 quantity"
                                                                type="number"
                                                                step="1"
                                                                min="1"
                                                                name="reference_number"
                                                                value={refNum !== null ? refNum : ""}
                                                                // value={purchaseOrder.reference_number}
                                                                disabled={isEditable ? false : true}
                                                                placeholder={
                                                                    referenceType === "po" ? "Automatic" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 required">
                                                    <label>{props.t("Suppliers")}:</label>
                                                    <div className="d-flex">
                                                        <div className="pe-2 w-100 client_custom-dropdown">
                                                            <SupplierCustomDropdown
                                                                data={allClients}
                                                                getClientData={getClientData}
                                                                clientLoader={clientLoader}
                                                                setMainFormDisabled={setMainFormDisabled}
                                                                setClientSelect={setSelectClientId}
                                                                setClientAddress={setClientData}
                                                                setClientFull={setClients}
                                                                clientNameData={clientNameData}
                                                                setClientNameData={setClientNameData}
                                                                clientDetail={clientDetail}
                                                                getAssests={AssetsData}
                                                                isEditable={isEditable}
                                                            />
                                                        </div>

                                                        <div className="pe-2">
                                                            <button
                                                                className="btn btn-light"
                                                                readOnly={isEditable ? false : true}
                                                                onClick={() => {
                                                                    setSelectClientModal(true), getClientData();
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                <i className="fas fa-search" />
                                                            </button>
                                                        </div>
                                                        <Link
                                                            className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                                                                }`}
                                                            to={`/suppliers/${selectClientId}`}
                                                            target="_blank"
                                                        >
                                                            <i className="fas fa-eye" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label>{props.t("Payment Option")}</label>
                                                    <div className="d-flex justify-content-between">
                                                        {paymentLoader ? (
                                                            <div className="pe-2">
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                ></Spinner>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="w-100 pe-2">
                                                            <AvField
                                                                type="select"
                                                                name="payment_option"
                                                                disabled={isEditable ? false : true}
                                                                // value={selectedCates}
                                                                value={
                                                                    paymentOption !== null ? paymentOption : ""
                                                                }
                                                                onChange={(e) => {
                                                                    setSelectedCates(e.target.value),
                                                                        e.target.value === "1"
                                                                            ? setBank(true)
                                                                            : setBank(false);
                                                                }}
                                                            >
                                                                <option value="" hidden>
                                                                    {props.t("Select")}
                                                                </option>
                                                                {paymentData &&
                                                                    paymentData.map((payment) => (
                                                                        <option value={payment.id} key={payment.id}>
                                                                            {payment.name}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            readOnly={isEditable ? false : true}
                                                            onClick={() => setIsOpenModalNew(true)}
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                            }}
                                                            type="button"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            + {props.t("New")}
                                                        </button>
                                                    </div>
                                                </div>
                                                {bank ? (
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Bank Account")}
                                                            type="select"
                                                            readOnly={isEditable ? false : true}
                                                            name="bank_account"
                                                            value={
                                                                purchaseOrder.bank_account !== null
                                                                    ? purchaseOrder.bank_account
                                                                    : ""
                                                            }
                                                        >
                                                            <option value="no_account">
                                                                {props.t("Without bank account")}
                                                            </option>
                                                            {allBanks &&
                                                                allBanks.map((bank, i) => (
                                                                    <option value={bank.id} key={i}>
                                                                        {bank.name}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {/* <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Title")}
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="title"
                                                        value={
                                                            purchaseOrder.title !== null
                                                                ? purchaseOrder.title
                                                                : ""
                                                        }
                                                    />
                                                </div> */}
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Purchase Document Ref.")}
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="purchase_document_ref"
                                                        value={
                                                            purchaseOrder.purchase_document_ref !== null
                                                                ? purchaseOrder.purchase_document_ref
                                                                : ""
                                                        }
                                                    ></AvField>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label>{props.t("Date")}</label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(e) => setStartDate(e)}
                                                        dateFormat="dd/MM/yyyy"
                                                        //   minDate={new Date()}
                                                        name="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Status")}
                                                        disabled={isEditable ? false : true}
                                                        type="select"
                                                        name="status"
                                                        value={
                                                            purchaseOrder.status !== null
                                                                ? purchaseOrder.status
                                                                : ""
                                                        }
                                                    >
                                                        {JSON.parse(localStorage.getItem("CustomStates"))?.map((item) => item.name === 'Purchase Order' ?
                                                            <>
                                                                {item.states?.map((option) =>
                                                                    <option value={option.name} key={option.id}>{props.t(option.name)}</option>
                                                                )}
                                                            </>
                                                            :
                                                            ""
                                                        )}
                                                        {/* <option value="" hidden>
                              {props.t("Select")}
                            </option>
                            <option value="pending">
                              {props.t("Pending")}
                            </option>
                            <option value="refused">
                              {props.t("Refused")}
                            </option>
                            <option value="In Progress">
                              {props.t("In Progress")}
                            </option>
                            <option value="received">
                              {props.t("Received")}
                            </option> */}
                                                    </AvField>
                                                </div>
                                                <div className="mb-3">
                                                    <label>{props.t("Created by")}:</label>
                                                    <span
                                                        onClick={() => setAssignedModal(true)}
                                                        className="form-control"
                                                        style={{
                                                            minHeight: "35px",
                                                            backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        }}
                                                    >
                                                        {assignedUserName}
                                                    </span>
                                                    {/* </input> */}
                                                </div>
                                                <div className="mb-3">
                                                    <label>{props.t("Agent")}</label>
                                                    <span
                                                        onClick={() => setAssignedModal2(true)}
                                                        className="form-control"
                                                        style={{
                                                            minHeight: "35px",
                                                            backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        }}
                                                    >
                                                        {agentUserName}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("1");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Items")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("2");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("More Information")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "attachments",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("attachments");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Attachments")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "5",
                                                    })}`}
                                                    onClick={() => {
                                                        toggleCustom("5");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Signature")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "6",
                                                    })} `}
                                                    onClick={() => {
                                                        toggleCustom("6");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Related")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={customActiveTab} className="p-3">
                                            <TabPane tabId="1" className="table-responsive">
                                                <Row>
                                                    <Col md={3} className="p-0">
                                                        <div className="button-section">
                                                            <button
                                                                className="btn btn-primary"
                                                                type="button"
                                                                onClick={() => addFormFields()}
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + &nbsp; {props.t("New Item")}
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    <Col md={9}></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="p-0">
                                                        <div>
                                                            <table width="100%">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>{props.t("Reference")}</th>
                                                                        <th>{props.t("Name")}</th>
                                                                        <th>{props.t("Description")}</th>
                                                                        <th style={{ width: "75px" }}>
                                                                            {props.t("Purchase Price ($)")}
                                                                        </th>
                                                                        <th>{props.t("Quantity")}</th>
                                                                        <th>{props.t("Disc. %")}</th>
                                                                        <th>{props.t("Subtotal ($)")}</th>
                                                                        <th>{props.t("Tax")} %</th>
                                                                        <th>{props.t("Other Tax")}</th>
                                                                        <th>{props.t("Delete")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {fields.formValues &&
                                                                        fields.formValues.map((element, i) => (
                                                                            <ProductTechEstimates
                                                                                key={i}
                                                                                i={i}
                                                                                data={element}
                                                                                fields={fields}
                                                                                clientId={selectClientId}
                                                                                setFields={setFields}
                                                                                discountValue={discountValue}
                                                                                metaDiscount={metaDiscount}
                                                                                //   isEditable={"false"}
                                                                                isEditable={isEditable}
                                                                                setMainFormDisabled={
                                                                                    setMainFormDisabled
                                                                                }
                                                                            />
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <Row className="row pt-3 pb-2">
                                                            <Col>
                                                                <div
                                                                    style={{
                                                                        borderBottom: "1px solid #ced4da",
                                                                    }}
                                                                ></div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="">
                                                            <div className="d-flex justify-content-end pe-5 ms-2">
                                                                <div>
                                                                    <label>{props.t("Disc. %")}: </label>
                                                                    <input
                                                                        style={{
                                                                            width: "70px",
                                                                        }}
                                                                        className="input-style"
                                                                        readOnly={isEditable ? false : true}
                                                                        min="0"
                                                                        type="number"
                                                                        name="meta_discount"
                                                                        value={((totalDisc / (totalBasePrice + totalDisc)) * 100).toFixed(2)}
                                                                        disabled
                                                                        onChange={(e) => {
                                                                            setDiscountValue(e.target.value),
                                                                                metaDiscount(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    {" "}
                                                                    <input
                                                                        style={{
                                                                            width: "70px",
                                                                        }}
                                                                        disabled
                                                                        value={totalDisc.toFixed(2)}
                                                                        className="ms-1 input-style"
                                                                        name="d_value"
                                                                        readOnly={isEditable ? false : true}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="sub-total-div mt-5">
                                                            <div className="sub-total">
                                                                <h6>{props.t("Subtotal ($)")}:</h6>
                                                                <p>{totalBasePrice.toFixed(2)}</p>
                                                            </div>
                                                            {totalDisc !== 0 || totalDisc !== NaN ? (
                                                                <div className="sub-total">
                                                                    <h6>{props.t("Discount (%)")}:</h6>
                                                                    <p>{totalDisc.toFixed(2)}</p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="sub-total">
                                                                <h6>{props.t("IVA ($)")}:</h6>
                                                                <p>{totalTax.toFixed(2)}</p>
                                                            </div>
                                                            {otherTax !== 0 ? (
                                                                <div className="sub-total">
                                                                    <h6>{props.t("IR ($)")}:</h6>
                                                                    <p>{otherTax.toFixed(2)}</p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="sub-total2">
                                                                <h6>{props.t("Total ($)")}:</h6>
                                                                <p>
                                                                    {subTotalShow
                                                                        ? subTotalShow.toFixed(2)
                                                                        : subTotal.toFixed(2)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col lg={6} md={6} className="mt-3">
                                                        <div className="mb-3">
                                                            <label className="me-2">Sent date:</label>
                                                            <DatePicker
                                                                selected={sentDate}
                                                                onChange={(e) => setSentDate(e)}
                                                                disabled={isEditable ? false : true}
                                                                minDate={new Date()}
                                                                // value={purchaseOrder && purchaseOrder.sent_date}
                                                                name="sent_date"
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} md={6} className="mt-3">
                                                        <label>{props.t("Delivery options")}</label>
                                                        <div className="d-flex justify-content-between">
                                                            {deliveryOptionLoader ? (
                                                                <div className="pe-2">
                                                                    <Spinner
                                                                        animation="border"
                                                                        role="status"
                                                                    ></Spinner>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="w-100 pe-2">
                                                                <AvField
                                                                    className="d-flex justify-content-between"
                                                                    type="select"
                                                                    name="delivery_option"
                                                                    disabled={isEditable ? false : true}
                                                                    onChange={handleSelectDeliveryOption}
                                                                    value={
                                                                        purchaseOrder.delivery_option !== null
                                                                            ? purchaseOrder.delivery_option
                                                                            : ""
                                                                    }
                                                                >
                                                                    <option value="" hidden>
                                                                        {props.t("Select")}
                                                                    </option>
                                                                    {deliveryOption &&
                                                                        deliveryOption.map((item, i) => (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </AvField>
                                                            </div>
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                onClick={() => setDeliveryOptionModal(true)}
                                                                readOnly={isEditable ? false : true}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + {props.t("New")}
                                                            </button>
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} md={6} className="mt-3">
                                                        <div className="mb-3">
                                                            <label className="me-2">
                                                                {props.t("Email Sent date")}:
                                                            </label>
                                                            <DatePicker
                                                                selected={emailDate}
                                                                onChange={(e) => setEmailDate(e)}
                                                                disabled={isEditable ? false : true}
                                                                dateFormat="dd/MM/yyyy"
                                                                name="email_sent_date"
                                                                autoComplete="off"
                                                                className="is-untouched is-pristine av-valid form-control"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} md={6} className="mt-3">
                                                        <AvField
                                                            type="Number"
                                                            name="currency_rate"
                                                            label={props.t("Currency Rate")}
                                                            readOnly={isEditable ? false : true}
                                                            value={
                                                                purchaseOrder.currency_rate !== null
                                                                    ? purchaseOrder.currency_rate
                                                                    : ""
                                                            }
                                                        />
                                                    </Col>

                                                    <Col lg={6} md={6} className="mt-3">
                                                        <AvField
                                                            type="select"
                                                            name="currency"
                                                            label={props.t("Currency")}
                                                            disabled={isEditable ? false : true}
                                                            value={
                                                                purchaseOrder.currency !== null
                                                                    ? purchaseOrder.currency
                                                                    : ""
                                                            }
                                                        >
                                                            <option value="inr">INR</option>
                                                            <option value="usd">USD</option>
                                                            <option value="gbp">GBP</option>
                                                        </AvField>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3 mt-3">
                                                            <AvField
                                                                label={props.t("Comments")}
                                                                type="textarea"
                                                                name="comments"
                                                                readOnly={isEditable ? false : true}
                                                                value={
                                                                    purchaseOrder.comments !== null
                                                                        ? purchaseOrder.comments
                                                                        : ""
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Private Comments")}
                                                                type="textarea"
                                                                name="private_comments"
                                                                readOnly={isEditable ? false : true}
                                                                value={
                                                                    purchaseOrder.private_comments !== null
                                                                        ? purchaseOrder.private_comments
                                                                        : ""
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="mt-3">
                                                        <AvField
                                                            label={props.t("Addendum")}
                                                            type="textarea"
                                                            name="addendum"
                                                            readOnly={isEditable ? false : true}
                                                            value={
                                                                purchaseOrder.addendum !== null
                                                                    ? purchaseOrder.addendum
                                                                    : ""
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mt-3">
                                                        <div>
                                                            <AvField
                                                                label={props.t("Created from")}
                                                                type="text"
                                                                readOnly={isEditable ? false : true}
                                                                name="created_from"
                                                                value={props.t("Web")}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="attachments">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="attach_btn">
                                                            <div
                                                                className="float-end d-flex btn btn-primary ms-2"
                                                                onClick={() => setIsOpenModals(true)}
                                                            >
                                                                {props.t("New")}
                                                            </div>
                                                            <div
                                                                className="float-end d-flex btn btn-primary"
                                                                onClick={() => getSendAttachment()}
                                                            >
                                                                {props.t("Send via email")}
                                                            </div>
                                                            <div>
                                                                <Table striped className="align-middle mt-5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>{props.t("Name")}</th>
                                                                            <th>{props.t("Description")}</th>
                                                                            <th>{props.t("Upload Date")}</th>
                                                                            <th>Activity</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {attachDocument &&
                                                                            attachDocument.map((item, i) => (
                                                                                <AllAttachment
                                                                                    key={i}
                                                                                    Attached={item}
                                                                                    user={poId}
                                                                                    selectedIds={selectedIds}
                                                                                    setSelectedIds={setSelectedIds}
                                                                                    selectedName={selectedName}
                                                                                    setSelectedName={setSelectedName}
                                                                                />
                                                                            ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="5">
                                                <Row>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                label={props.t("Name")}
                                                                type="text"
                                                                name="name"
                                                                value={
                                                                    purchaseOrder.name !== null
                                                                        ? purchaseOrder.name
                                                                        : ""
                                                                }
                                                                readOnly={isEditable ? false : true}
                                                            />
                                                        </div>
                                                        <div className="mb-3 required">
                                                            <AvField
                                                                label={props.t("CED/RUC")}
                                                                type="number"
                                                                onKeyPress={(e) => {
                                                                    if (e.target.value.length == 13)
                                                                        e.preventDefault();
                                                                }}
                                                                name="tin"
                                                                value={
                                                                    tinValue !== "" ? tinValue : purchaseOrder.tin
                                                                }
                                                                disabled={isEditable ? false : true}
                                                                required="required"
                                                            />
                                                        </div>
                                                        <div className="company_img img-flex">
                                                            <label>{props.t("Main image")}</label>
                                                            <input
                                                                className="hidden d-none"
                                                                onChange={(e) => setComImg(e)}
                                                                type="file"
                                                                id="comImg"
                                                            />
                                                            <label
                                                                className="company_img-box"
                                                                htmlFor={isEditable ? "comImg" : ""}
                                                            >
                                                                <img src={imgPreview} />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </AvForm>
                        )}
                    </div>
                    <Modal size="lg" isOpen={isOpenModals} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => createAttachment(e)}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Document")}</label>
                                            <input
                                                type="file"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit">
                                            {props.t("Submit")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenModals(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    {assignedModal ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal}
                            getAssignedUser={getAssignedUser}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}

                    {assignedModal2 ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal2}
                            getAssignedUser={getAgentUser}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}
                </Container>
            </div>

            <Modal size="lg" isOpen={assetModal} centered={true}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("New Assest")}
                    </h5>
                </div>
                <div className="modal-body p-0">
                    <AssetsNew
                        clientData={clients}
                        modalData={assetModal}
                        setassetModal={setassetModal}
                        AssetsData={AssetsData}
                    />
                </div>
            </Modal>

            {isOpenModalNew ? (
                <NewPayment
                    setIsOpenModal={setIsOpenModalNew}
                    NewpaymentShow={NewpaymentShow}
                />
            ) : (
                ""
            )}
            {selectClientModal ? (
                <ClientList
                    setSelectClientModal={setSelectClientModal}
                    setSelectClientId={setSelectClientId}
                    clients={allClients}
                    clientDetail={clientDetail}
                    getSuppliers={getSuppliers}
                />
            ) : (
                ""
            )}
            {selectClientModal2 ? (
                <ClientList
                    setSelectClientModal={setSelectClientModal2}
                    setSelectClientId={setSelectClientId2}
                    clients={allClients}
                    clientDetail={clientDetail2}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
};
export default withTranslation()(PurchaseOrderEdit);
PurchaseOrderEdit.propTypes = {
    t: PropTypes.any,
};
